import { Formik, FormikProps } from "formik";
import { DocumentNode } from "graphql";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { Col, Container } from "react-grid-system";
import * as Yup from "yup";
import { gqlMutation, gqlMutationSilent, gqlQuery } from "../../../common/lib";
import { Values } from "./Values";
import cn from "classnames";

export class AdminFormBase<P> extends React.Component<AdminFormBaseProps & P> {
  protected formName = "";
  protected createMutation: any;
  protected editMutation: any;
  protected requiredString = "This field is required";
  protected acceptedFields: string[] = [];
  protected requiredStrings: string[] = [];
  protected lengthLimitations = {};
  protected resetOnAdd = true;
  protected formColSize = this.props.colSize ?? 5;
  protected createFormWidth = "unset" as string | number;
  protected editFormWidth = "unset" as string | number;
  protected clsName = "";
  protected _lenientDirty = false; // this flags is required for components that pass around hash objects. Hopefully it will one day become unnecessary.
  protected enableReinitialize = true;
  protected silent = false;
  protected formRef;

  public static defaultProps = {
    context: "modal" as "inline" | "modal",
    omitTitle: false
  };

  state = {
    formErrors: [],
    formErrorData: null
  };

  protected validateOnChange = true;
  initialValues = {};

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  get objectName() {
    return this.props.objectName;
  }

  protected formQuery = null as DocumentNode | null;

  protected get formEditQuery(): DocumentNode | null {
    return null;
  }

  protected prepare_fields = {};

  protected validationSchema() {
    const y = Yup.object();
    const obj = {};

    for (const field of this.acceptedFields) {
      const isRequired = this.requiredStrings.includes(field);
      const lengthLimitation: number = this.lengthLimitations[field];
      if (!isRequired && !lengthLimitation) {
        continue;
      }

      let rule = Yup.string();
      if (isRequired) {
        rule = rule.required(this.requiredString);
      }
      if (lengthLimitation) {
        rule = rule.max(lengthLimitation, `This field has to be equal or less than ${lengthLimitation} characters`);
      }
      obj[field] = rule;
    }

    return y.shape(obj);
  }

  get verb() {
    return this.props.object ? "Update" : "Create";
  }

  values(mutationData, formQueryValues = null) {
    let { object } = this.props;
    const acceptedFields = this.acceptedFields.concat(["gid"]);
    const initialValues = new Values(acceptedFields, this.initialValues);
    if (object) {
      initialValues.feed(object);
    }
    if (mutationData) {
      // @ts-expect-error
      object = Object.values(mutationData)[0];
    }
    if (object) {
      return this.initialValuesFromObject(initialValues, object, formQueryValues);
    }
    return initialValues;
  }

  initialValuesFromObject(initialValues: any, gqlObject: GQLObject, formQueryValues: any) {
    // generic attempt at replacing joined fields with proper values
    for (const field of this.acceptedFields) {
      if (field.endsWith("_id")) {
        try {
          initialValues[field] = gqlObject[field.replace("_id", "")].id;
        } catch (e) {
          initialValues[field] = null;
        }
      }
      if (field.endsWith("_ids")) {
        try {
          initialValues[field] = gqlObject[field.replace("_id", "")].map((x) => x.id);
        } catch (e) {
          initialValues[field] = [];
        }
      }
      if (this.prepare_fields[field]) {
        initialValues[field] = this.prepare_fields[field](gqlObject[field]);
      }
    }
    return initialValues;
  }

  protected objectToOption(obj: any) {
    return {
      key: obj.id!,
      text: obj.name!,
      value: obj.name!
    };
  }

  protected get title() {
    return `${this.verb} ${this.objectName ?? ""}`;
  }

  protected get submitButtonClass() {
    return "form-submit";
  }

  protected get cancelButtonClass() {
    return "form-cancel";
  }

  protected submitLabel(props = undefined, res = undefined) {
    return this.title;
  }

  protected onSubmit(props) {
    props.submitForm();
  }

  protected beforeSubmit(values: Values, formQueryData: any) {}

  renderPrimaryButton(props, res = undefined) {
    let isDirty = props.dirty;
    if (this._lenientDirty) {
      isDirty = Object.values(props.touched).length > 0;
    }

    return (
      <PrimaryButton
        disabled={
          props.isSubmitting ||
          !isDirty ||
          !props.isValid ||
          !this.formLevelValidation(props.values, res) ||
          !!Object.keys(props.errors).length
        }
        className={this.submitButtonClass}
        onClick={() => this.onSubmit(props)}
        data-testid="submit-object"
      >
        {this.submitLabel(props, res)}
      </PrimaryButton>
    );
  }

  renderCancelButton(props) {
    return (
      <DefaultButton className={this.cancelButtonClass} onClick={this.props.onClose} data-testid="cancel-object">
        Cancel
      </DefaultButton>
    );
  }

  renderButtons(props, res) {
    return (
      <>
        {this.renderPrimaryButton(props, res)}
        {!(props.modal?.current) && this.renderCancelButton(props)}
        {props.modal?.current?.createCancelButton()}
        {this.renderAdditionalButtons()}
      </>
    );
  }

  get record_exists() {
    return !!this.props.object;
  }

  get isCreating() {
    return !this.props.object;
  }

  protected renderErrors() {
    if (!this.state.formErrors) {
      return null;
    }

    return (
      <>
        {this.state.formErrors.map((error) => (
          <span key={error} className="formErrors">
            {error}
          </span>
        ))}
      </>
    );
  }

  render() {
    const mutation = this.isCreating ? this.createMutation : this.editMutation;
    const { formErrorData } = this.state;
    const { context, omitTitle } = this.props;

    let clsName = !this.record_exists ? "create-form" : "update-form";
    if (this.clsName) {
      clsName += ` ${this.clsName}`;
    }

    const title = this.title ? <h2 className="formTitle">{this.title}</h2> : null;

    const mutationComponent = this.silent ? gqlMutationSilent : gqlMutation;

    return (
      <Container fluid className={[clsName, context].join(" ")} data-testid={clsName}>
        <Col sm={this.formColSize}>
          {!omitTitle && context === "modal" && title}
          {mutationComponent(mutation, (mutateFunc, data) =>
            this.runFormQuery((res) => {
              return (
              <Formik
                initialValues={formErrorData ?? this.values(data, res)}
                enableReinitialize={this.enableReinitialize}
                validationSchema={this.validationSchema()}
                validateOnChange={this.validateOnChange}
                validateOnBlur={this.validateOnChange}
                onSubmit={this.submit(mutateFunc, res)}
                innerRef={this.formRef}
                render={(props) => (
                  <form
                    className={this.props.formClassName}
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.onSubmit(props);
                    }}
                    style={{ width: this.isCreating ? this.createFormWidth : this.editFormWidth }}
                  >
                    {!omitTitle && context === "inline" && title}
                    {this.renderErrors()}
                    {this.renderFormFields(props, res)}
                    <div className={cn("padding-separate", { "form-buttons": this.formName !== "TargetCompanyForm" })}>{this.renderButtons(props, res)}</div>
                    <div style={{ clear: "both" }} />
                  </form>
                )}
              />
              );
            })
          )}
        </Col>
      </Container>
    );
  }

  protected formQueryVariables = undefined as any;
  protected formQueryOptions = {};

  runFormQuery(callback) {
    let { formQuery, formQueryVariables } = this;
    if (formQuery) {
      if (this.formEditQuery && this.props.object) {
        formQuery = this.formEditQuery;
        formQueryVariables = { id: this.props.object.id };
      }
      return gqlQuery(formQuery, formQueryVariables, callback, this.formQueryOptions);
    }
    return callback(null);
  }

  protected onSubmitCompleted(data) {
    const refetch = this.props.refetch ?? window.apolloClient.resetStore;
    this.setState({
      formErrors: [],
      formErrorData: null
    });
    if (!this.props.object) {
      this.resetOnAdd && refetch();
    }
    this.props.onClose?.();
  }

  protected onSubmitFailed() {}

  protected formLevelValidation(values: Values, formQueryData: any): boolean {
    // This function can stop the submit process by returning false
    return true;
  }

  protected getVariables(values) {
    return { input: values.clean() };
  }

  protected submit(mutateFunc: any, formQueryData: any) {
    const me = this;
    return function(values: Values) {
      if (!me.formLevelValidation(values, formQueryData)) {
        return false;
      }
      me.beforeSubmit(values, formQueryData);

      return mutateFunc({ variables: me.getVariables(values) })
        .then((data) => me.onSubmitCompleted(data))
        .catch((errors) => {
          for (const error of errors.graphQLErrors) {
            if (error.extensions.error_type == "json_error") {
              me.setState(
                {
                  formErrors: JSON.parse(error.message),
                  formErrorData: values
                },
                () => me.onSubmitFailed()
              );
              me.forceUpdate();
            }
          }
        });
    };
  }

  protected renderFormFields(props: FormikProps<Values>, res: any): JSX.Element | null {
    return null;
  }

  protected renderAdditionalButtons(): JSX.Element | null {
    return null;
  }
}
