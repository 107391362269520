import gql from "graphql-tag";
import { IconButton, IDropdownOption } from "office-ui-fabric-react";
import React from "react";
import { GQLTag, GQLTier, GQLTopic, GQLTrack } from "../../../schemas/schema";
import { gqlQuery } from "../../lib";
import { DropdownEnhanced } from "../DropdownEnhanced";
import { IAssignmentDefinition } from "./interfaces";
import { PopupTagSelector } from "./PopupTagSelector";

interface IIndicatorAssignmentLineProps {
  allTracks: GQLTrack[]
  allTags: GQLTag[]
  allTiers: GQLTier[]
  emphasisTiers: GQLTier[]
  canDelete: boolean
  assignment: IAssignmentDefinition
  alterAssignment: (opts) => void
}

export class IndicatorAssignmentLine extends React.Component<IIndicatorAssignmentLineProps> {
  topics_for_indicator_query = gql`
    query topics_for_track($track_id: ID!) {
      topics_for_track(track_id: $track_id) {
        id
        name
        is_enabled
      }
    }
  `;

  render() {
    const { assignment, allTracks, allTags, allTiers, emphasisTiers } = this.props;
    const track_options = allTracks.map((x) => ({ text: x.name, key: x.id })) as IDropdownOption[];
    const tier_options = allTiers.map((x) => ({ text: x.name, key: x.id + "" })) as IDropdownOption[];
    const emphasisTierOptions = emphasisTiers.map((x) => ({ text: x.name, key: x.id + "" })) as IDropdownOption[];

    return (
      <tr>
        <td>
          <DropdownEnhanced
            data-testid={"field-track-select"}
            placeholder={"Select Track..."}
            className={"select"}
            onChange={(e, value) => {
              if (e.type == "focus") {
                return;
              }
              !Array.isArray(value) &&
                this.alterDefinition({
                  track: allTracks.find((k) => k.id == value.key),
                  tags: []
                });
            }}
            options={track_options}
            selectedKey={assignment.track ? assignment.track.id + "" : undefined}
          />
        </td>
        <td>
          {assignment.track &&
            gqlQuery(this.topics_for_indicator_query, { track_id: assignment.track.id }, (res) => (
              <DropdownEnhanced
                data-testid={"field-topic-select"}
                placeholder={"Select Topic..."}
                className={"select"}
                onChange={(e, value) => {
                  if (e.type == "focus") {
                    return;
                  }
                  !Array.isArray(value) &&
                    this.alterDefinition({
                      topic: res.topics_for_track.find((x) => x.id == value.key)
                    });
                }}
                options={this.getTopics(res, assignment)}
                selectedKey={assignment.topic ? assignment.topic.id + "" : undefined}
              />
            ))}
        </td>
        <td>
          {assignment.track && assignment.topic && (
            <DropdownEnhanced
              data-testid={"field-tier-select"}
              placeholder={"Select Tier..."}
              className={"select"}
              onChange={(e, value) => {
                if (e.type == "focus") {
                  return;
                }
                !Array.isArray(value) &&
                  this.alterDefinition({
                    tier: allTiers.find((x) => x.id == value.key)
                  });
              }}
              options={tier_options}
              selectedKey={assignment.tier ? assignment.tier.id + "" : undefined}
            />
          )}
        </td>
        <td>
          {assignment.track && assignment.topic && assignment.tier && (
              <DropdownEnhanced
                data-testid={"field-essential-select"}
                className={"select"}
                placeholder={"Select Essential/Additional..."}
                onChange={(e, value) => {
                  if (e.type == "focus") {
                    return;
                  }
                  !Array.isArray(value) &&
                    this.alterDefinition({
                      emphasis_tier: emphasisTiers.find((x) => x.id == value.key)
                    });
                }}
                options={emphasisTierOptions}
                selectedKey={assignment.emphasis_tier ? assignment.emphasis_tier.id + "" : undefined}
              />
          )}
        </td>
        <td>
          {assignment.track && assignment.topic && assignment.tier && (
            <PopupTagSelector
              onChange={(tags) => this.alterDefinition({ tags })}
              allTags={allTags}
              value={assignment.tags}
            />
          )}
        </td>
        <td>
          {this.props.canDelete && (
            <IconButton
              className={"btn-delete"}
              iconProps={{ iconName: "trash" }}
              onClick={() => this.alterDefinition({ delete: true })}
            />
          )}
        </td>
      </tr>
    );
  }

  private getTopics(res, assignment: IAssignmentDefinition): IDropdownOption[] {
    let filter = x => x.is_enabled;
    if (assignment.topic) {
      filter = x => (x.id == assignment.topic.id) || x.is_enabled;
    }
    return res.topics_for_track
      .filter(filter)
      .map((x) => ({ text: x.name, key: x.id })) as IDropdownOption[];
  }

  private alterDefinition(opts: {
    tier?: GQLTier
    track?: GQLTrack
    topic?: GQLTopic
    emphasis_tier?: GQLTier
    tags?: GQLTag[]
    delete?: boolean
  }) {
    this.props.alterAssignment(opts);
  }
}
