import cn from "classnames";
import { marked } from "marked";
import React from "react";
import { ReportIndicatorContext } from "../contexts";
import {
  assertion_tag_to_string,
  get_corsis_tags,
  get_corsis_tags_by_line,
  IMPLEMENTATION_TAGS,
  implementation_tag_to_string
} from "./common";
import "./MarkdownWrapper.style.scss";

const parsedMarked = (text, highlight = false, assertions = []) => {
  const parse = [] as string[];
  const all_assertions = get_corsis_tags_by_line(text).filter((x) => x.value.name == "Assertion");
  for (const line of text.split("\n")) {
    const tags = get_corsis_tags(line);
    if (tags.length > 0) {
      if (IMPLEMENTATION_TAGS.includes(tags[0].name)) {
        const is_plural = all_assertions.filter((x) => x.value.params.context == tags[0].name).length > 1;
        parse.push(`<p><b class="bold-important">${implementation_tag_to_string(tags[0], is_plural)}</b></p>`);
        continue;
      }
      if (tags[0].name == "Assertion") {
        if (highlight) {
          const isNew = !tags[0].params.id;
          const isModified =
            !isNew && assertions.find((x) => x.id == tags[0].params.id && x.value != tags[0].extraParam);

          parse.push(
            `<li><span class='${cn("inline", { "is-new": isNew, "is-modified": isModified })}'>
                                    ${assertion_tag_to_string(tags[0])} </span></li>`
          );
        } else {
          parse.push(`<li>${assertion_tag_to_string(tags[0])}</li>`);
        }
        parse.push("");
        continue;
      }
    }
    parse.push(line);
  }
  return marked(parse.join("\n"));
};

export const MarkdownWrapper = ({ highlight, children }: { highlight?: boolean, children: any }) => {
  const text = (children || "") as string;
  let assertions = [];
  if (highlight) {
    const context = React.useContext(ReportIndicatorContext.ReactContext);
    assertions = context.assertions;
  }
  return <span className="markdown" dangerouslySetInnerHTML={{ __html: parsedMarked(text, highlight, assertions) }} />;
};
